<template>
    <div class="action-share-done">
        <div class="title" v-html="$translate('ACTION_SHARE_DONE_TITLE')" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionShareDone',
    props: ['message'],
    computed: {
        body() {
            const name = this.$mustParse(this.message.content).name

            return this.$translate('ACTION_SHARE_DONE_BODY').replace(/%s/, name)
        },
    },
}
</script>
